

.flexWrapper {
    height: 100%;
    width: 100%;
    background-color: #72a2ac;
}

.flexWrapper h1 {
    color: lightyellow;
}

.searchField {
  width: 300px !important;
  margin: 10px;
  float: right;
  margin-right: 15px;
}