html, body {
    height: 100%;
    width: 100%;   
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100%;
}