.loginForm {
    width: 500px;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
}

.flexWrapper {
    height: 100%;
    width: 100%;
    background-color: #72a2ac;
}

.loginButton {
    width: 100%;
    border-radius: 50px !important;
    font-weight: bold !important;
}

.form-control {
    border: none !important;
    border-bottom: 2px solid #d9d9d9 !important;
    border-radius: 0 !important;
    color: #333 !important;
}

.loginFormHeader {
    text-align: center;
    padding-bottom: 20px;
}